import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import logo from './circular-works-logo.svg';

function App() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Email submitted: ${email}`);
  };

  return (
    <div className="App">
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-6 text-center col-sm-12">
          <div className="svg-container">
            <img className="" src={logo} alt="Circular Works Logo" />
            </div>
            <p>
              Software company that develops solutions and strategies for circular economy
              development in the construction and real estate industry, with a focus on
              material passport services.{" "}
            </p>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control custom-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className="btn btn-primary" type="submit">
                  Stay Updated
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

